import React from 'react';
import styled from '@emotion/styled'
import { Inner } from 'styles/structure';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import RichText from '../components/RichText';
import Content from '../components/_ui/Content';
import ResourcesGrid from 'components/_grid/Resources';
import ResourceCard from 'components/_card/Resource';
import PostCard from 'components/_card/Post';
import ArrowLink from '../components/_ui/ArrowLink';

const ResourcesContainer = styled.div`
  position: relative;
  width: 100%;
  
  &.FeaturedResources--resource,
  &.FeaturedResources--program {
    background-color: ${colors.grey100};
    padding: 6.4rem 0 11.2rem;
  }
  
  &.FeaturedResources--program {
    & + * {
      margin-top: 0;
    }
  }
  
  header {
    > div {
      > * + * {
        margin-top: 1.6rem;
      }
      
      & + * {
        margin-top: 3.2rem;
      }
    }
    
    & + * {
      margin-top: 4rem;
    }
  }
  
  & + * {
    margin-top: 6.4rem;
  }

  &.FeaturedResources--career-hub {
    background-color: ${colors.grey200};
    padding: 6.4rem 0 6rem;

    h2 {
      font-size: 3.2rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    &.FeaturedResources--program {
      & + * {
        margin-top: 0;
      }
    }
    
    & + * {
      margin-top: 10.4rem;
    }
    
    header {
      display: flex;
      align-items: center;
      
      > div {
        flex: 1 1 100%;
        
        > * {
          max-width: 62.8rem;
        }
      }
      
      a {
        flex-shrink: 0;
      }
      
      & + * {
        margin-top: 5.6rem;
      }
    }
  }
`;

const FeaturedResources = ({ heading, content, buttonText, destination, resources, variant, mixed }) => {
  let resourcesHeading;

  if (heading && heading.raw) {
    resourcesHeading = <RichText render={heading.raw}/>;
  } else if (heading) {
    resourcesHeading = <h3>{heading}</h3>;
  }

  return (
    <ResourcesContainer className={`${variant ? `FeaturedResources--${variant}` : ''}`}>
      <Inner>
        {(heading || content || (buttonText && destination)) && (
          <header>
            <div>
              {resourcesHeading}
              {content && <Content content={content.raw} />}
            </div>

            {(buttonText && destination) && (
              <ArrowLink destination={destination} text={buttonText} />
            )}
          </header>
        )}

        {(resources && resources.length > 0) && (
          <ResourcesGrid dense={resources.length === 4 || resources.length > 6}>
            {resources.map((feature, i) => {
              if (feature.node) {
                return (
                  <li key={`featured-resource-${i}`}>
                    <ResourceCard
                      uid={feature.node.uid}
                      image={feature.node.data.preview_image || feature.node.data.feature_image}
                      title={feature.node.data.title[0].text}
                      type={feature.node.data.resource_type && feature.node.data.resource_type.title}
                      topic={feature.node.data.topic && feature.node.data.topic.title}
                      preview_text={feature.node.preview_text}
                    />
                  </li>
                );
              } else if (feature.resource) {
                const { document } = feature.resource;

                if (document) {
                  return (
                    <li key={`featured-resource-${i}`}>
                      <ResourceCard
                        uid={document.uid}
                        image={document.data.preview_image || document.data.feature_image}
                        title={document.data.title.text}
                        type={document.data.resource_type && document.data.resource_type.document.data.title}
                        topic={document.data.topic && document.data.topic.document.data.title}
                        preview_text={document.data.preview_text}
                      />
                    </li>
                  )
                }
              } else if (feature.item) {
                const { document } = feature.item;

                if (document.type === 'resource') {
                  return (
                    <li key={`featured-resource-${i}`}>
                      <ResourceCard
                        uid={document.uid}
                        image={document.data.preview_image || document.data.feature_image}
                        title={document.data.title.text}
                        type={document.data.resource_type && document.data.resource_type.document.data.title}
                        topic={document.data.topic && document.data.topic.document.data.title}
                        preview_text={document.data.preview_text}
                      />
                    </li>
                  )
                } else if (document.type === 'post') {
                  return (
                    <li key={`featured-resource-${i}`}>
                      <PostCard
                        title={document.data.title.text}
                        uid={document.uid}
                        image={document.data.preview_image || document.data.feature_image}
                        category={(document.data.category && document.data.category.document) && document.data.category.document.data.title}
                        mixed={mixed}
                      />
                    </li>
                  )
                }

                return null;
              }

              return null;
            })}
          </ResourcesGrid>
        )}
      </Inner>
    </ResourcesContainer>
  );
}

export default FeaturedResources;
