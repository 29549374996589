import React from 'react';
import RichText from 'components/RichText';
import styled from '@emotion/styled'
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';
import { Rubric } from "styles/typography";
// import Content from "components/_ui/Content";
import ArrowLink from 'components/_ui/ArrowLink';
import colors from "styles/colors";

const HeroContainer = styled.div`
  position: relative;
  width: 100%;

  & + * {
    margin-top: 5.6rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 8rem;
    }
  }
`;

const HeroInner = styled(Inner)`
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.teal900};
  
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(63,126,124);
    background: linear-gradient(60deg, rgba(63,126,124,0.5) 0%, rgba(63,126,124,0) 100%);
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    left: 4rem;
    width: calc(100% - 8rem);

    .Hero--feature & {
      width: calc(100% - 22.4rem);
    }
  }
`;

const HeroWrap = styled.div`
  position: relative;
  z-index: 1;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    min-height: 22.4rem;
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    padding: 0 6.4rem;
    
    .Hero--feature & {
      padding-right: 0;  
    }
  }
`;

const HeroContent = styled.div`
  * {
    color: #fff;  
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    h1 {
      padding-bottom: 3rem;
    }
    
    h2, h3, h4 {
      padding-bottom: 3.8rem;
    }
    
    .Hero--feature & {
      max-width: 60rem;
    }
  }
`;

const HeroCard = styled.div`
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 4rem 3.2rem;
  box-shadow: 0 0.3rem 2rem rgba(0,0,0,0.05);
  margin-top: 4.8rem;
  margin-bottom: -16rem;
  
  > div {
    margin-top: 1.6rem;
    width: 85%;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    width: 46.4rem;
    flex: 0 0 46.4rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 14.4rem;
  }
`;

const IntroInner = styled(Inner)`
  margin-top: 14.4rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 8.8rem;
  }
`;

const IntroWrap = styled(Wrap)`
`;

const IntroText = styled.h3`
  max-width: 84.8rem;
  font-weight: 500;
`;

const Hero = ({ heading, text, background, feature }) => {
  return (
    <HeroContainer className={`${feature ? `Hero--feature` : ''}`}>
      <HeroInner>
        {background && (
          <HeroBackground>
            <img src={background.url} alt={background.alt} />
          </HeroBackground>
        )}

        <HeroWrap>
          <HeroContent>
            {heading && <RichText render={heading.raw} />}
          </HeroContent>

          {feature && (
          <HeroCard>
            {feature.rubric && <Rubric><span>{feature.rubric}</span></Rubric>}

            {feature.title && <RichText render={feature.title.raw} />}

            {feature.text && <div><p>{feature.text}</p></div>}

            {feature.destination && <ArrowLink text={feature.cta} destination={feature.destination} />}
          </HeroCard>
          )}
        </HeroWrap>
      </HeroInner>

      {text && (
        <IntroInner>
          <IntroWrap>
            <IntroText>{text}</IntroText>
          </IntroWrap>
        </IntroInner>
      )}
    </HeroContainer>
  );
};

export default Hero;
