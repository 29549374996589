import React from 'react';
import styled from '@emotion/styled'
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import Play from 'vectors/play.svg'
import VideoModal from 'components/_video/Modal';
import { document } from "browser-monads";

const PosterContainer = styled.button`
  appearance: none;
  position: relative;
  width: 100%;
  margin-bottom: 4rem;
  cursor: pointer;
  text-align: left;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 60%;
    
    &:hover {
      > div {
        background-color: ${colors.teal600};
        
        > div {
          color: #fff;
        }
      }
      
      svg {
        fill: #fff;
      }
      
      span {
        color: #fff;
      }
      
      img {
        transform: scale(1.05);
      }
    }
  }
`;

const PosterPlay = styled.div` 
  color: #fff;
  display: block;
  position: absolute;
  bottom: -5rem;
  left: -3.2rem;
  width: 28rem;
  background-color: ${colors.yellow500};
  color: ${colors.grey900};
  z-index: 20;
  padding: 4rem;
  transition: background 0.16s ease-in-out;
  box-shadow: 0 0.3rem 2rem rgba(0,0,0,0.16);
  
  > div {
    display: flex;
    align-items: center;
    
    & + * {
      margin-top: 1.6rem;
    }
    
    &::after {
      content: 'Watch Video';
      color: inherit;
      font-size: 1.6rem;
      font-weight: 500;
      transition: color 0.16s ease-in-out;
    }  
  }
  
  svg {
    width: 3rem;
    height: 3rem;
    fill: ${colors.grey900};
    margin-right: 0.8rem;
    transition: fill 0.16s ease-in-out;
  }
`;

const PosterCTA = styled.span`
  display: block;
  font-size: 2.4rem;
  font-weight: 600;
  color: ${colors.grey900}
  line-height: 1.22;
  transition: color 0.16s ease-in-out;
`;

const PosterImage = styled.div`
  position: relative;
  width: 100%;
  max-width: 49rem;
  overflow: hidden;
  box-shadow: 0 0.3rem 2rem rgba(0,0,0,0.16);
  
  &::after {
    content: "";
    display: block;
    padding-bottom: 78%;
  }
    
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-out;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-left: auto;
  }
`;

class Poster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      modalContent: null,
    };
  }

  openModal = () => {
    this.setState({
      isOpen: true,
      modalContent: this.props.embed,
    });

    document.getElementsByTagName('body')[0].classList.add('is-locked');
  }

  closeModal = () => {
    this.setState({
      isOpen: false,
      modalContent: null,
    });

    document.getElementsByTagName('body')[0].classList.remove('is-locked');
  }

  captureEscape(event) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  componentDidMount() {
    document.onkeydown = this.captureEscape.bind(this);
  }

  render() {
    return (
      <>
        <PosterContainer
          className={`${this.props.variant ? `Poster--${this.props.variant}` : ''} ${this.state.isOpen ? `is-open` : ''}`}
          onClick={() => { this.openModal()}}
        >
          <PosterPlay>
            <div>
              <Play/>
            </div>

            {this.props.cta && <PosterCTA>{this.props.cta}</PosterCTA>}
          </PosterPlay>

          <PosterImage>
            <img src={this.props.poster.url} alt={this.props.poster.alt} />
          </PosterImage>
        </PosterContainer>

        <VideoModal
          embed={this.state.modalContent}
          closeModal={this.closeModal}
        />
      </>
    );
  }
}

export default Poster;
