import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'components/Seo';
import Layout from 'components/Layout';
import Quote from 'components/Quote';
import Hero from 'components/_page/homepage/Hero';
import WhoWeAre from 'components/_page/homepage/WhoWeAre';
import WhoWereFor from 'components/_page/homepage/WhoWereFor';
import FeaturedResources from 'components/FeaturedResources';
import { withPreview } from 'gatsby-source-prismic';

const RenderBody = ({ home }) => (
  <>
    <Hero
      heading={home.hero_heading}
      text={home.hero_text}
      background={home.hero_background}
      feature={{
        rubric: home.hero_feature_rubric,
        title: home.hero_feature_title,
        text: home.hero_feature_text,
        destination: home.hero_feature_destination.url,
        cta: home.hero_feature_cta ?? "Read More"
      }}
    />

    <WhoWereFor
      heading={home.programs_heading}
      content={home.programs_content}
      poster={home.programs_poster}
      embed={home.programs_video_embed.text}
      cta={home.programs_cta}
      programs={home.featured_programs}
    />

    <FeaturedResources
      heading={home.featured_content_heading}
      resources={home.featured_content}
      variant='homepage'
      mixed
    />

    <FeaturedResources
      heading={home.resources_heading || 'Resource Library'}
      content={home.resources_content}
      resources={home.featured_resources.slice(0,4)}
      buttonText='View the Resource Library'
      destination='/resources'
      variant='homepage'
    />

    <WhoWeAre
      heading={home.programs_heading}
      content={home.programs_content}
      poster={home.programs_poster}
      embed={home.programs_video_embed.text}
      cta={home.programs_cta}
      programs={home.featured_programs}
    />

    <Quote
      content={home.quote.document.data.content}
      name={home.quote.document.data.name}
      job_title={home.quote.document.data.job_title}
      organization={home.quote.document.data.organization}
    />
  </>
);

const Homepage = ({ data }) => {
  //Required check for no data being returned
  const home = data.allPrismicHomepage.edges[0].node.data;

  return (
    <Layout>
      <Seo
        title={home.social_title || 'Home'}
        description={home.social_description}
        image={home.social_image}
      />

      <RenderBody home={home}/>
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicHomepage {
      edges {
        node {
          data {
            hero_heading {
              raw
              text
            }
            hero_text
            hero_background {
              url
              alt
            }
            hero_feature_rubric
            hero_feature_title {
              raw
            }
            hero_feature_text
            hero_feature_cta
            hero_feature_destination {
              url
            }
            get_involved {
              heading {
                raw
              }
              content {
                raw
              }
              button_text
              destination
            }
            featured_content_heading {
              raw
            }
            featured_content {
              item {
                document {
                  ... on PrismicResource {
                    uid
                    type
                    data {
                      title {
                        raw
                        text
                      }
                      resource_type {
                        document {
                          ... on PrismicType {
                            data {
                              title
                            }
                          }  
                        }
                      }
                      topic {
                        document {
                          ... on PrismicTopic {
                            data {
                              title
                            }
                          }  
                        }
                      }
                      preview_text
                      preview_image {
                        url
                      }
                      feature_image {
                        url
                        alt
                      }
                    }
                  }
                  ... on PrismicPost {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      deck
                      category {
                        document {
                          ... on PrismicCategory {
                            data {
                              title
                            }
                          }  
                        }
                      }
                      feature_image {
                        url
                        alt
                      }
                      preview_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
            programs_heading {
              raw
            }
            programs_content {
              raw
            }
            programs_poster {
              url
              alt
            }
            programs_video_embed {
              raw
              text
            }
            programs_cta
            featured_programs {
              title
              text
              destination
              image {
                url
                alt
              }
            }
            resources_heading {
              raw
            }
            resources_content {
              raw
            }
            featured_resources {
              resource {
                document {
                  ... on PrismicResource {
                    uid
                    data {
                      title {
                        raw
                        text
                      }
                      resource_type {
                        document {
                          ... on PrismicType {
                            data {
                              title
                            }
                          }  
                        }
                      }
                      topic {
                        document {
                          ... on PrismicTopic {
                            data {
                              title
                            }
                          }  
                        }
                      }
                      preview_text
                      preview_image {
                        url
                        alt
                      }
                      feature_image {
                        url
                        alt
                      }
                    }
                  }  
                }
              }
            }
            collections_content {
              raw
            }
            collections_topics {
              topic {
                document {
                  ... on PrismicTopic {
                    uid
                    data {
                      title
                    }
                  }  
                }
              }
            }
            quote {
              document {
                ... on PrismicQuote {
                  data {
                    content
                    name
                    job_title
                    organization
                  }
                }
              }
            }
            social_title
            social_description
            social_image {
              url
            }
          }
        }
      }
    }
  }
`;

export default withPreview(Homepage);