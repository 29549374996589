import React from 'react';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import PropTypes from 'prop-types';
import { Inner } from 'styles/structure';
import dimensions from 'styles/dimensions';
import QuoteIcon from 'vectors/quote.svg';

const QuoteContainer = styled.div`
  background-color: ${colors.teal600};
  padding-top: 5.6rem;
  padding-bottom: 8rem;

  &.Quote--career-hub {
    margin-top: 0;
  }
  
  svg {
    fill: #fff;
    fill-opacity: 0.2;
    width: 5.8rem;
    height: 5.5rem;
    margin-bottom: 2.4rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-top: 11.2rem;
    
    svg {
      position: absolute;
      right: calc(100% + 2.4rem);
      top: -4rem;
      width: 8.8rem;
      height: 8.3rem;
      fill-opacity: 0.2;

    }
  }
`;

const QuoteInner = styled(Inner)`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    // regular padding plus breathing room for SVG
    padding: 0 15.2rem;
  }
`;

const QuoteContent = styled.div`
  position: relative;
  color: #fff;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    max-width: 84.8rem;
    margin: 0 auto;
  }
`;

const QuoteText = styled.q`
  display: block;
  font-size: 2.4rem;

  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 3.2rem;
  }
`;

const QuoteAttribution = styled.div`
  margin-top: 4rem;


  span {
    display: block;
    color: #fff;

  }
`;

const QuoteName = styled.span`
  font-size: 1.6rem;


  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
  }
`;

const QuoteTitle = styled.div`

  font-size: 1.6rem;
`;

const Quote = ({ content, name, job_title, organization, variant }) => (
  <QuoteContainer className={`${variant ? `Quote--${variant}` : ''}`}>
    <QuoteInner>
      <QuoteContent>
        <QuoteIcon />

        <QuoteText>{content}</QuoteText>

        <QuoteAttribution>
          <QuoteName>{name}</QuoteName>

          <QuoteTitle>
            {job_title && <span>{job_title}</span>}

            {organization && <span>{organization}</span>}
          </QuoteTitle>
        </QuoteAttribution>
      </QuoteContent>
    </QuoteInner>
  </QuoteContainer>
);

export default Quote;

Quote.propTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  job_title: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
};
