import React from 'react';
import styled from '@emotion/styled'
import { Inner, Wrap } from 'styles/structure';
// import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import ProgramsCard from 'components/_page/homepage/ProgramsCard';

const ProgramsContainer = styled.div`
  background: rgb(246,246,246);
  background: linear-gradient(0deg, rgba(246,246,246,1) 95%, rgba(246,246,246,0) 100%);
  padding: 14.4rem 0 6.4rem;
  margin-top: -8rem;
  
  header {
    & + * {
      margin-top: 4.8rem;
    }
  }
  
  & + * {
    margin-top: 6.4rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 24rem 0 16rem;
    margin-top: -14.4rem;
   
    header {
      & + * {
        margin-top: 6.4rem;
      }
    }
  
    & + * {
      margin-top: 10.4rem;
    }
  }
`;

/*const ProgramsWrap = styled.div`
  & + * {
    margin-top: 9.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: flex-start;
    
    & + * {
      margin-top: 10.4rem;
    }
  }
`;

const ProgramsContent = styled.div`
  & + * {
    margin-top: 5.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 2 1 40%;
    max-width: 34.6rem;
    
    & + * {
      margin-top: 0;
      margin-left: 8.2vw;
    }
  }
`;

const ProgramsVideo = styled.div`
  position: relative;
  width: 100%;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 60%;
  }
`;*/

const ProgramsList = styled.div`
  li {
    list-style: none;
    
    & + * {
      margin-top: 3.2rem;
    }
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    li {
      & + * {
        margin-top: 11.2rem;
      }
      
      &:nth-child(even) {
        > a {
          flex-direction: row-reverse;

          > div:first-of-type {
            margin-right: 0;
          }
          
          > div:last-of-type {
            left: 4.8rem;
          }
        }
      }
    }
  }
`;

const WhoWereFor = ({ programs }) => {
  return (
    <ProgramsContainer>
      <Inner>
        <Wrap>
          {(programs && programs.length > 0) && (
            <ProgramsList>
              {programs.map((program, i) => (
                <li key={i}>
                  <ProgramsCard
                    destination={program.destination}
                    image={program.image}
                    title={program.title}
                    text={program.text}
                  />
                </li>
              ))}
            </ProgramsList>
          )}
        </Wrap>
      </Inner>
    </ProgramsContainer>
  );
}

export default WhoWereFor;
