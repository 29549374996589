import React from 'react';
import { Link } from "gatsby";
import styled from '@emotion/styled';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import ArrowRight from 'vectors/arrow-right.svg';

const ProgramContainer = styled(Link)`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: flex-end;
    
    &:hover {
      img {
        transform: scale(1.05);
      }
      
      h2,h3,h4 {
        color: ${colors.yellow500};
      }
      
      span {
        transform: translateX(0.8rem);
      }
    }
  }
`;

const ProgramImage = styled.div`
  display: block;
  position: relative;
  width: calc(90% - 2.4rem);
  background-color: ${colors.teal900};
  overflow: hidden;
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 63.75%;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.33s ease-in-out;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 0 0 calc(50% + 4.8rem);
    margin-right: -4.8rem;
  }
`;

const CardContainer = styled.div`
  display: block;
  position: relative;
  width: 90%;
  margin-top: -14.4rem;
  margin-left: 4.8rem;
  background-color: #fff;
  padding: 4rem 3.2rem;
  box-shadow: 0 0.3rem 2rem rgba(0,0,0,0.05);
  
  h2, h3, h4 {
    transition: color 0.18s ease-in-out;
    
    & + * {
      margin-top: 1.6rem;
    }
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 4rem 6.4rem 4.8rem;
    top: 4.8rem;
    margin-left: 0;
    flex: 0 0 calc(50% + 4.8rem);
    
    h4 {
      font-size: 3.2rem;
    }
  }
`;

const CardContent = styled.div``;

const CardArrow = styled.span`
  display: block;
  position: relative;
  width: 2.9rem;
  height: 1.7rem;
  margin-top: 4rem;
  transition: transform 0.18s ease-in-out;
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: ${colors.yellow500};
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 2.4rem;
  }
`;

const ProgramsCard = ({ destination, image, title, text }) => {
  return (
    <ProgramContainer to={destination || '/'}>
      <ProgramImage>
        <img src={image.url} alt={image.alt}/>
      </ProgramImage>

      <CardContainer >
        {title && <h3>{title}</h3>}

        <CardContent>
          {text && <p>{text}</p>}

          <CardArrow>
            <ArrowRight />
          </CardArrow>
        </CardContent>
      </CardContainer>
    </ProgramContainer>
  );
}

export default ProgramsCard;
