import React from 'react';
import styled from '@emotion/styled'
import { Inner, Wrap } from 'styles/structure';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import RichText from "../../RichText"
import Content from 'components/_ui/Content';
import Poster from 'components/_video/Poster';
// import ProgramsCard from 'components/_page/homepage/ProgramsCard';

const ProgramsContainer = styled.div`
  background-color: ${colors.grey100};
  padding: 6.4rem 0;
  
  header {
    & + * {
      margin-top: 4.8rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 10.4rem 0 12rem;
   
    header {
      & + * {
        margin-top: 6.4rem;
      }
    }
  }
`;

const ProgramsWrap = styled.div`
  & + * {
    margin-top: 9.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: flex-start;
    
    & + * {
      margin-top: 10.4rem;
    }
  }
`;

const ProgramsContent = styled.div`
  & + * {
    margin-top: 5.6rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 2 1 40%;
    max-width: 34.6rem;
    
    & + * {
      margin-top: 0;
      margin-left: 8.2vw;
    }
  }
`;

const ProgramsVideo = styled.div`
  position: relative;
  width: 100%;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 60%;
  }
`;

/*const ProgramsList = styled.div`
  li {
    list-style: none;
    
    & + * {
      margin-top: 3.2rem;
    }
  }
`;*/

const WhoWeAre = ({ heading, content, poster, embed, cta }) => {
  return (
    <ProgramsContainer>
      <Inner>
        <Wrap>
          {heading && (
            <header>
              <RichText render={heading.raw}/>
            </header>
          )}

          <ProgramsWrap>
            {content && (
              <ProgramsContent>
                <Content
                  content={content.raw}
                  variant='homepage-programs'
                />
              </ProgramsContent>
            )}

            {(poster && embed) && (
              <ProgramsVideo>
                <Poster
                  poster={poster}
                  embed={embed}
                  cta={cta}
                />
              </ProgramsVideo>
            )}
          </ProgramsWrap>
        </Wrap>
      </Inner>
    </ProgramsContainer>
  );
}

export default WhoWeAre;
