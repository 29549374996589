import React from 'react';
import styled from '@emotion/styled'
/*import colors from "styles/colors";*/
import dimensions from 'styles/dimensions';
import Close from '../../vectors/close.svg';

const ModalContainer = styled.div`
  background-color: rgba(45,45,45,0.92);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 7.2rem 2rem 4rem;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.12s ease-in-out, transition 0s 0.12s;
  
  .is-open + & {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.12s ease-in-out, transition 0s 0s;
  }
  
  button {
    display: block;
    appearance: none;
    position: absolute;
    top: 8.8rem;
    right: 1.2rem;
    padding: 0.8rem;
    cursor: pointer;
  }
  
  svg {
    display: block;
    width: 4rem;
    height: 4rem;
    fill: #fff;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 10.4rem 12.4rem 4rem;
    
    button {
      top: 14.4rem;
      right: 4rem;
    }
  }
`;

const ModalWrap = styled.div`
  position: relative;
  max-width: 120rem;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  
  iframe {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

class ModalVideo extends React.Component {
  render() {
    return (
      <ModalContainer className={`${this.props.variant && `Modal--${this.props.variant}`}`}>
        <button onClick={() => {this.props.closeModal()}}>
          <Close/>
        </button>

        <ModalWrap>
          {(this.props.embed) && (
            <ModalContent dangerouslySetInnerHTML={{__html: this.props.embed}}/>
          )}

        </ModalWrap>
      </ModalContainer>
    );
  }
}

export default ModalVideo;
